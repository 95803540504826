import { FunctionComponent, ReactNode, useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import TopCenter from "../../components/pageViews/topCenter";

import TopRight from "../../components/pageViews/topright";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";

import livingRoomImage1 from "../../assets/image/living room/C5479474_11.jpg";
import livingRoomImage2 from "../../assets/image/living room/C5479474_4.jpg";
import livingRoomImage3 from "../../assets/image/living room/C5487872.jpg";
import livingRoomImage4 from "../../assets/image/living room/C5487872_4.jpg";
import livingRoomImage5 from "../../assets/image/living room/C5487872_5.jpg";
import livingRoomImage6 from "../../assets/image/living room/C5487872_6.jpg";
import livingRoomImage7 from "../../assets/image/living room/C5744904_10.jpg";
import livingRoomImage8 from "../../assets/image/living room/C5744904_13.jpg";
import livingRoomImage9 from "../../assets/image/living room/C5744904_4.jpg";
import livingRoomImage10 from "../../assets/image/living room/C5744904_5.jpg";
import livingRoomImage11 from "../../assets/image/living room/C5744904_8.jpg";
import livingRoomImage12 from "../../assets/image/living room/e1b8a_11.jpg";

import FooterMain from "../../components/footer";
interface LivingRoomProps {}

const LivingRoom: FunctionComponent<LivingRoomProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <div className="hidden lg:block">
        <ReactPageScroller {...pageScrollerProps}>
          <TopCenter
            title={"Family room"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[livingRoomImage3, livingRoomImage4]}
          ></TopCenter>
          <TopRight
            title={"Dining Room"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
            }
            img={[livingRoomImage1, livingRoomImage2]}
          />
          <MoreDetailsSlider
            onSlideChange={handleSwiperSlideChange}
            title={" More LivingRoom design"}
            dialogTitle={"Living Room"}
            img={[
              livingRoomImage5,
              livingRoomImage6,
              livingRoomImage7,
              livingRoomImage8,
              livingRoomImage9,
              livingRoomImage10,
              livingRoomImage11,
              livingRoomImage12,
              livingRoomImage9,
              livingRoomImage10,
              livingRoomImage11,
              livingRoomImage12,
              livingRoomImage9,
              livingRoomImage10,
              livingRoomImage11,
              livingRoomImage12,
            ]}
            content={[
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
            ]}
          >
            <FooterMain />
          </MoreDetailsSlider>
        </ReactPageScroller>
      </div>
      <div className="block lg:hidden">
        <TopCenter
          title={"Family room"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[livingRoomImage3, livingRoomImage4]}
        ></TopCenter>
        <TopRight
          title={"Dining Room"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
          }
          img={[livingRoomImage1, livingRoomImage2]}
        />
        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More LivingRoom design"}
          dialogTitle={"Living Room"}
          img={[
            livingRoomImage5,
            livingRoomImage6,
            livingRoomImage7,
            livingRoomImage8,
            livingRoomImage9,
            livingRoomImage10,
            livingRoomImage11,
            livingRoomImage12,
            livingRoomImage9,
            livingRoomImage10,
            livingRoomImage11,
            livingRoomImage12,
            livingRoomImage9,
            livingRoomImage10,
            livingRoomImage11,
            livingRoomImage12,
          ]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        >
          <FooterMain />
        </MoreDetailsSlider>
      </div>
    </>
  );
};

export default LivingRoom;
