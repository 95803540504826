import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import cabinet1 from "../../assets/image/image 43 copy.jpg";
import cabinet2 from "../../assets/image/image 43 copy.jpg";
import Arrow from "../../assets/icon/arrow";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import FooterMain from "../footer";

import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import sliceArrayIntoGroupsOfFour from "../../helpers/arrayhelper";

interface MoreDetailsSliderProps {
  title: string;
  dialogTitle?: string;
  img: any[];
  content: string[];
  children?: ReactNode;
  onSlideChange?: any;
}

const MoreDetailsSlider: FunctionComponent<MoreDetailsSliderProps> = ({
  title,
  img,
  content,
  dialogTitle,
  children,
  onSlideChange,
}) => {
  const [open, setOpen] = useState(false);
  const [swiperImageSelect, setSwiperImageSelect] = useState(Number);
  const navigate = useNavigate();

  const location = useLocation();
  const handleOpen = (number: number) => {
    setOpen(!open);
    setSwiperImageSelect(number);
    if (open === true) {
      navigate(window.location.pathname);
    }
  };
  useEffect(() => {
    if (location.hash === "") {
      setOpen(false);
    }
  }, [location]);

  // useEffect(()=>{
  //  console.log( sliceArrayIntoGroupsOfFour(img))
  // },[img])
  const swiperInit = {
    modules: [Navigation, Autoplay],
    spaceBetween: 60,
    slidesPerView: 3.9,
    onSlideChange: onSlideChange,
    loop: true,
    dir: "ltr",
    // allowTouchMove: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    touchStartForcePreventDefault: true,
    hashNavigation: true,
    navigation: {
      nextEl: "#prevRef",
      prevEl: "#nextRef",
    },
    breakpoints: {
      "@0.00": {
        slidesPerView: 1.4,
        spaceBetween: 20,
      },
      "@0.75": {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      "@1.00": {
        slidesPerView: 2.7,
        spaceBetween: 20,
      },
      "@1.50": {
        slidesPerView: 3.9,
        spaceBetween: 30,
      },
    },
  };
  const swiperInit1 = {
    modules: [Navigation, Autoplay],
    spaceBetween: 30,
    slidesPerView: 1.3,
    onSlideChange: onSlideChange,
    loop: true,
    dir: "ltr",
    
    allowTouchMove: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    touchStartForcePreventDefault: true,
    hashNavigation: true,
    navigation: {
      nextEl: "#prevRef",
      prevEl: "#nextRef",
    },
   
    
  };
  return (
    <div
      id="page1-2"
      className="relative flex flex-col justify-start w-screen h-screen bg-gray-100 dark:bg-black bg-opacity-90 "
    >
      <Fragment>
        <Dialog open={open} handler={handleOpen} size="xxl">
          <section
            className="flex flex-col h-full cursor-pointer lg:flex-row"
            onClick={() => handleOpen(swiperImageSelect)}
          >
            <Fade left>
              <section className="flex items-center justify-center w-full min-h-[50%] lg:p-40 lg:w-2/3 lg:h-full">
              <Swiper {...swiperInit1} >
                {
                  sliceArrayIntoGroupsOfFour(img)[swiperImageSelect]?.map((item)=>{

                    return  <SwiperSlide><img src={item} alt="" className="w-full" /></SwiperSlide>
                  })
                }
               </Swiper>
              </section>
            </Fade>
            <Fade right>
              <section className="flex items-start w-full h-full px-10 bg-black lg:items-center lg:p-0 lg:w-1/3 lg:h-full ">
                <div className="lg:border lg:border-l-0 w-full border-mainColor lg:my-[100px] lg:mr-[80px] lg:p-[55px] lg:pl-20 relative">
                  <h3 className="left-0 py-10 text-3xl bg-black lg:p-5 lg:absolute lg:text-5xl -top-12 text-mainColor font-Inria">
                    <Arrow
                      className="inline-block w-3 ml-2 mr-3 lg:w-6"
                      color="#FF8A00"
                      size={24}
                    />
                    {dialogTitle} {swiperImageSelect}
                  </h3>
                  <p className="font-light text-white leading-[200%]">
                    {/* {content[swiperImageSelect]} */}
                  </p>
                </div>
              </section>
            </Fade>
          </section>
        </Dialog>
      </Fragment>
      <Fade bottom big duration={2000}>
        <h2 className="mb-10 text-2xl text-center text-black pt-28 dark:text-white lg:text-5xl font-Inria lg:pt-40">
          {title}
        </h2>
      </Fade>
      <Fade right big duration={2500}>
        <div className="relative w-screen px-5 lg:px-0 lg:pt-10">
          <Swiper {...swiperInit} className="" style={{ display: "grid" }}>
            {img &&
              img.length > 0 &&
              sliceArrayIntoGroupsOfFour(img)?.map((img, index) => {
                return (
                  <SwiperSlide>
                    <div
                      className={` aspect-square overflow-hidden mx-auto cursor-pointer flex flex-wrap ${
                        index === swiperImageSelect && `w-full`
                      }`}
                      onClick={() => {
                        handleOpen(index);
                        navigate(window.location.pathname + `#image_${index}`);
                      }}
                    >
                      {img?.map((item, index) => {
                        return <img src={item} alt="" className="w-1/2 border border-white aspect-square border-5" />;
                      })}
                    </div>
                  </SwiperSlide>
                );
              })}

            <div
              className="absolute flex  left-1 lg:left-10 z-50 rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-6 h-6 lg:w-12 lg:h-12"
              id="nextRef"
            >
              <Arrow className="w-2 " />
            </div>
            <div
              className="absolute flex  right-1 lg:right-10 z-50 rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-6 h-6  lg:w-12 lg:h-12"
              id="prevRef"
            >
              <Arrow className="w-2 rotate-180" />
            </div>
          </Swiper>
        </div>
      </Fade>
      {children}
      <FooterMain />
    </div>
  );
};

export default MoreDetailsSlider;
