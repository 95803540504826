import { FunctionComponent } from "react";
import Facebook from "../../assets/icon/facebook";
import Instagram from "../../assets/icon/instagram";
import LocationIcon from "../../assets/icon/location";
import PhoneNumberIcon from "../../assets/icon/phoneNumber";
import Telegram from "../../assets/icon/telegram";
import Whatsapp from "../../assets/icon/whatsapp";
import logo from "../../assets/logo/logo.svg";
import logolight from "../../assets/logo/logolight.svg";
import Facebooklight from "../../assets/icon/facebookLight";
import Whatsapplight from "../../assets/icon/whatsappLight";
import Telegramlight from "../../assets/icon/telegramLight";
import InstagramLight from "../../assets/icon/instagramLight";
interface FooterMainProps {
  classNameprops?: string;
}

const FooterMain: FunctionComponent<FooterMainProps> = ({ classNameprops }) => {
  return (
    <footer
      className={`dark:bg-[#151515] bg-white  lg:block z-30 w-screen lg:px-14 px-10 py-2 absolute bottom-0 py-[15px] ${classNameprops}`}
    >
      <ul className="flex flex-col justify-between w-full lg:flex-row">
        <li className="flex items-center justify-center text-center lg:order-1">
          <a
            href="/"
            className="flex flex-col items-center justify-center text-black dark:text-white lg:relative lg:flex-row "
          >
            {/* <img
              src={logo}
              alt=""
              className="inline-block w-10 p-2 mb-2 lg:mb-0 lg:w-10 lg:mr-2 dark:bg-transparent"
            /> */}
          <img src={logo} alt="" className="hidden w-6 mb-1 bg-black dark:bg-transparent dark:inline-block lg:w-6 lg:mr-2 " />
            <img src={logolight} alt="" className="inline-block w-6 mb-1 dark:hidden lg:w-6 lg:mr-2 dark:bg-transparent" />
            Square
          </a>
        </li>
        <li className="hidden border-r border-r-white lg:order-2"></li>
        <li className="flex items-center mt-5 justify-evenly lg:justify-between lg:order-3 lg:mt-0">
          <a href="/" className="inline-block text-sm text-black dark:text-white lg:text-base ">
            Home
          </a>
          <div className="border-r dark:lg:border-r-white lg:border-r-black border-r-mainColor w-[1px] h-4 lg:h-3/5 mx-6 visible">
            {" "}
          </div>
          <a
            href="/aboutus"
            className="inline-block text-sm text-black dark:text-white lg:text-base "
          >
            About us
          </a>
          <div className="border-r dark:lg:border-r-white lg:border-r-black border-r-mainColor  h-4 w-[1px] lg:h-3/5 mx-6 visible">
            {" "}
          </div>
          <a
            href="/contactus"
            className="inline-block text-sm text-black dark:text-white lg:text-base "
          >
            Contact us
          </a>
        </li>
        <li className="my-2 border-b lg:border-r dark:lg:border-r-white lg:border-r-black lg:order-4 border-b-white lg:my-0"></li>
        <li className="flex flex-row justify-between lg:order-5 ">
          <a className="my-1 text-black dark:text-white mr-14 lg:my-0 ">
            <LocationIcon className="inline-block mr-2 fill-black dark:fill-white" />
            Canada
          </a>
          <a className="my-1 text-black dark:text-white lg:my-0">
            <PhoneNumberIcon className="inline-block mr-2 fill-black dark:fill-white" />
            +1-202-2020
          </a>
        </li>
        <li className="my-2 border-b lg:border-r dark:lg:border-r-white lg:border-r-black lg:order-6 border-b-white lg:my-0"></li>

        <li className="flex-row justify-between hidden dark:flex lg:order-7 ">
          <Facebook className="p-1 mr-2 " />
          <Whatsapp className="p-1 mr-2 " />
          <Telegram className="p-1 mr-2 " />
          <Instagram  className="p-1 " />
        </li>
        <li className="flex flex-row justify-between dark:hidden lg:order-7">
          <Facebooklight className="p-1 mr-2 " />
          <Whatsapplight className="p-1 mr-2 " />
          <Telegramlight className="p-1 mr-2 " />
          <InstagramLight   className="p-1 " />
        </li>
      </ul>
    </footer>
  );
};

export default FooterMain;
