import { Children, FunctionComponent, ReactNode, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import image from "../../assets/image/image 10.jpg";
import image2 from "../../assets/image/image 11.jpg";
import ArrowRight from "../../assets/icon/arrowRight";
import Marquee from "react-fast-marquee";
import gif from "../../assets/animateAbout.gif";
import FooterMain from "../../components/footer";

interface AboutUsProps {
  children?: ReactNode;
}

const AboutUs: FunctionComponent<AboutUsProps> = (props) => {
  const swiperInit = {
    modules: [Navigation],
    spaceBetween: 30,
    slidesPerView: 3.3,
    // onSlideChange:onSlideChange,
    loop: true,
    allowTouchMove: false,
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    breakpoints: {
      "@0.00": {
        slidesPerView: 1.4,
        spaceBetween: 20,
      },
      "@0.75": {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      "@1.00": {
        slidesPerView: 2.7,
        spaceBetween: 20,
      },
      "@1.50": {
        slidesPerView: 3.3,
        spaceBetween: 30,
      },
    },
  };
  return (
    <>
      <section className="relative flex flex-col justify-start w-screen h-screen bg-no-repeat bg-cover lg:pt-20">
        <div>
          <div className="absolute top-0 bottom-0 left-0 right-0 z-40 ">
            <div className="absolute top-0 bottom-0 left-0 right-0 z-[5] bg-white dark:bg-black !bg-opacity-70 "></div>
            <img src={gif} alt="" className="w-screen h-screen " />
          </div>
          <section className="relative z-40 flex flex-col lg:flex-row ">
            <div className="flex flex-col items-start justify-center w-full px-5 pt-20 pb-5 text-left lg:py-0 lg:w-1/2 lg:px-36">
              <h3 className="mb-4 text-5xl font-normal text-left text-white font-Inria before:w-7 before:h-[3px] relative before:absolute before:-bottom-2 before:left-0 before:bg-white">
                .About us
              </h3>
              <p className="leading-relaxed text-left text-white">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before final copy is available.In
                publishing and graphic design
              </p>
            </div>
            <div className="relative flex h-[200px] lg:h-full items-center justify-center w-full lg:pl-10 lg:w-1/2">
              <Swiper {...swiperInit} className="h-full">
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
              <div
                className="cursor-pointer  lg:flex swiper-button-next_aboutUs rotate-180 lg:rotate-0 z-[10000]"
                id="nextRef"
              >
                <ArrowRight className="inline ml-2 rotate-0 lg:rotate-180" />
              </div>

              <div
                className="cursor-pointer lg:hidden  flex right-0 left-auto swiper-button-next_aboutUs z-[10000]"
                id="prevRef"
              >
                <ArrowRight className="inline ml-2 " />
              </div>
            </div>
          </section>
        </div>
        <section className="z-50 mt-10 mb-5">
          <Marquee>
            <p className="z-30 text-2xl text-white lg:text-8xl font-Inconsolata">
              Interior design Studio . Re building . Interior design Studio . Re
              building .{" "}
            </p>
          </Marquee>
        </section>
        {props.children}
      </section>
    </>
  );
};

export default AboutUs;
