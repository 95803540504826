import { FunctionComponent, ReactNode, useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";

import TopCenter from "../../components/pageViews/topCenter";
import TopRight from "../../components/pageViews/topright";
import BottomLeft from "../../components/pageViews/bottomLeft";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import FooterMain from "../../components/footer";

import bathroom1 from "../../assets/image/bathroom/C5479474_20.jpg";
import bathroom2 from "../../assets/image/bathroom/C5479474_22.jpg";
import bathroom3 from "../../assets/image/bathroom/C5487872_17.jpg";
import bathroom4 from "../../assets/image/bathroom/C5487872_21.jpg";
import bathroom5 from "../../assets/image/bathroom/C5744904_21.jpg";
import bathroom6 from "../../assets/image/bathroom/N5108973_30.jpg";
import bathroom7 from "../../assets/image/bathroom/e1b8a_29.jpg";
import bathroom8 from "../../assets/image/bathroom/e1b8a_30.jpg";
import bathroom9 from "../../assets/image/bathroom/e1b8a_31.jpg";
import bathroom10 from "../../assets/image/bathroom/e1b8a_35.jpg";
import bathroom11 from "../../assets/image/bathroom/e1b8a_36.jpg";

interface BathroomProps {}

const Bathroom: FunctionComponent<BathroomProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <div className="hidden lg:block">
        <ReactPageScroller {...pageScrollerProps}>
          <TopCenter
            title={"Bathroom 1"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[bathroom1, bathroom2]}
          />
          <TopRight
            title={"Bathroom 2"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[bathroom3, bathroom4]}
          />
          {/* <BottomLeft
            title={"Bathroom 3"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[bathroom5, bathroom6]}
          />
          <TopCenter
            title={"Bathroom 4"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[bathroom7, bathroom8]}
          />
          <TopRight
            title={"Bathroom 5"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[bathroom9, bathroom10, bathroom11]}
          /> */}

          <MoreDetailsSlider
            onSlideChange={handleSwiperSlideChange}
            title={" More Bathroom design"}
            dialogTitle={"Bath room "}
            img={[
              bathroom1,
              bathroom2,
              bathroom3,
              bathroom4,
              bathroom5,
              bathroom6,
              bathroom7,
              bathroom8,
              bathroom9,
              bathroom10,
              bathroom11,
              bathroom7,
              bathroom8,
              bathroom9,
              bathroom10,
              bathroom11,
            ]}
            content={[
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
            ]}
          >
            <FooterMain />
          </MoreDetailsSlider>
        </ReactPageScroller>
      </div>
      <div className="block lg:hidden">
        {" "}
        <TopCenter
          title={"Bathroom 1"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom1, bathroom2]}
        />
        <TopRight
          title={"Bathroom 2"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom3, bathroom4]}
        />
        {/* <BottomLeft
          title={"Bathroom 3"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom5, bathroom6]}
        />
        <TopCenter
          title={"Bathroom 4"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom7, bathroom8]}
        />
        <TopRight
          title={"Bathroom 5"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
          }
          img={[bathroom9, bathroom10, bathroom11]}
        /> */}
        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More Bathroom design"}
          dialogTitle={"Bath room "}
          img={[
            bathroom1,
            bathroom2,
            bathroom3,
            bathroom4,
            bathroom5,
            bathroom6,
            bathroom7,
            bathroom8,
            bathroom9,
            bathroom10,
            bathroom11,
            bathroom8,
            bathroom9,
            bathroom10,
            bathroom11,
          ]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        >
          <FooterMain />
        </MoreDetailsSlider>
      </div>
    </>
  );
};

export default Bathroom;
