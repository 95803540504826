import { FunctionComponent } from "react";
import image from "../../assets/image/Frame14447copy.jpg";
import Address from "../../assets/icon/location";
import Mail from "../../assets/icon/location";
import Phone from "../../assets/icon/phoneNumber";
import FooterMain from "../../components/footer";
interface ContactUsProps {}

const ContactUs: FunctionComponent<ContactUsProps> = () => {
  return (
    <>
      <section
        className="relative w-full h-full px-5 pb-16 bg-center bg-no-repeat bg-cover dark:bg-black lg:px-0 lg:h-screen lg:bg-cover lg:w-screen"
        style={{ backgroundImage: `url(${image})` }}
        // style={{backgroundImage:`url(${image})`}}
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-white dark:bg-black dark:bg-opacity-40 bg-opacity-20"></div>
        <div className="right-0 z-50 w-full h-full overflow-auto lg:pb-40 lg:absolute lg:w-1/2">
          <h2 className="pt-32 mb-8 text-4xl text-white font-Inria ">
            Contact us
          </h2>
          <ul>
            <li className="mb-6">
              {/* <img src={address÷} alt="" " /> */}
              <Address className="inline-block w-5 mr-2" />
              <span className="text-white font-Inria">
                California- street2-No1
              </span>
            </li>
            <li className="mb-6">
              {/* <img src={phone} alt="" className="inline-block w-5 mr-2" /> */}
              <Phone className="inline-block w-5 mr-2" />
              <span className="text-white font-Inria">+1 5287 9876</span>
            </li>
            <li className="mb-6">
              {/* <img src={mail} alt=""  /> */}
              <Mail className="inline-block w-5 mr-2" />
              <span className="text-white font-Inria">persian@gmail.com</span>
            </li>
          </ul>
          <p className="text-4xl text-white font-Inria mt-9">
            WE are waiting for you
          </p>
          <form action="mail" className="lg:w-[426px] w-full mt-6 ">
            <div className="flex flex-col gap-5 mb-5 lg:flex-row">
              <input
                type="text"
                className="bg-white bg-opacity-[0.15] p-2 dark:text-white text-black"
                placeholder="Name"
              />
              <input
                type="text"
                className="bg-white bg-opacity-[0.15] p-2 dark:text-white text-black"
                placeholder="Email"
              />
            </div>
            <div>
              <textarea
                name=""
                id=""
                className="w-full bg-white bg-opacity-[0.15] p-2 dark:text-white text-black"
                rows={10}
                placeholder="Massage"
              ></textarea>
            </div>
            <button type="submit" className="py-3 mt-5 bg-white px-7">
              send
            </button>
          </form>
        </div>
      </section>
      <FooterMain classNameprops="relative lg:absolute z-50 " />
    </>
  );
};

export default ContactUs;
