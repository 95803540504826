import { Fragment, FunctionComponent, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";

import closet1 from "../../assets/image/closet/C5479474_18.jpg";
import closet2 from "../../assets/image/closet/C5479474_19.jpg";
import closet3 from "../../assets/image/closet/C5487872_19.jpg";
import closet4 from "../../assets/image/closet/e1b8a_38.jpg";

import Arrow from "../../assets/icon/arrow";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import Fade from "react-reveal/Fade";

import CloseIcon from "../../assets/icon/close";
import FooterMain from "../../components/footer";
import SampleViewOne from "../../components/pageViews/SampleViewOne";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import TopRight from "../../components/pageViews/topright";
interface ClosetProps {}
SwiperCore.use([Navigation]);
const Closet: FunctionComponent<ClosetProps> = () => {
  const [open, setOpen] = useState(false);
  const [swiperImageSelect, setSwiperImageSelect] = useState(Number);
  const handleOpen = (e: any) => {
    // e.preventDefault();
    setOpen(!open);
    // setSwiperImageSelect(number);
  };
  const swiperInit = {
    modules: [Navigation],
    navigation: {
      nextEl: "#button_prev",
      prevEl: "#button_next",
    },
    dir: "rtl",
    className: "lg:w-3/6 px-3 lg:px-0",
    // style: { display: "grid" },

    slidesPerView: 1,
    speed: 1000,
    allowTouchMove: true,
    loop: true,
  };
  const mainswiperinit = {
    modules: [Navigation],
    navigation: {
      nextEl: "#main_perv",
      prevEl: "#main_next",
    },
    dir: "rtl",
    className: "",
    // style: { display: "grid" },
    spaceBetween: 50,
    slidesPerView: 3.5,
    loop: true,
    breakpoints: {
      "@0.00": {
        slidesPerView: 1.4,
        spaceBetween: 50,
      },
      "@0.75": {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      "@1.00": {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      "@1.50": {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  };
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <div className="hidden lg:block">
        <ReactPageScroller {...pageScrollerProps}>
          <SampleViewOne
            title={"Closet"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
            }
            img={[closet2, closet1, closet4, closet3]}
          />
          <TopRight
            title={"Bathroom 2"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[closet2, closet1, closet4, closet3]}
          />
          {/* <div
            id="page2-1"
            className="flex flex-col justify-start w-screen h-screen pt-20 overflow-auto bg-white dark:bg-black lg:pt-40 bg-opacity-90"
          >
            <Fragment>
              <Dialog
                open={open}
                handler={handleOpen}
                className="bg-white dark:bg-black"
                size="xxl"
              >
                <div className="w-screen h-screen py-32 ">
                  <button
                    onClick={handleOpen}
                    className="absolute p-5 border-2 border-black rounded dark:bg-white top-10 left-10 lg:top-30 lg:left-32"
                  >
                    <CloseIcon color="#fff" className="hidden dark:block" />
                    <CloseIcon color="#000" className="block dark:hidden" />
                  </button>
                  <div className="w-full mx-auto lg:w-3/4">
                    <Swiper {...swiperInit}>
                      <SwiperSlide>
                        <div className="w-full overflow-hidden ">
                          <img
                            src={closet1}
                            alt=""
                            className="w-full aspect-square"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="w-full overflow-hidden ">
                          <img
                            src={closet2}
                            alt=""
                            className="w-full aspect-square"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="w-full overflow-hidden ">
                          <img
                            src={closet3}
                            alt=""
                            className="w-full aspect-square"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="w-full overflow-hidden ">
                          <img
                            src={closet4}
                            alt=""
                            className="w-full aspect-square"
                          />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div
                    className="border dark:border-white border-black rounded-full absolute left-[0] lg:left-10 z-[1000]  flex items-center justify-center cursor-pointer  top-1/2 lg:top-1/2  w-12 h-12"
                    id="button_next"
                  >
                    <Arrow color="#fff" className="hidden dark:block" />
                    <Arrow color="#000" className="block dark:hidden" />
                  </div>
                  <div
                    className=" border dark:border-white border-black  rounded-full absolute right-[0] lg:right-10 z-[1000] flex items-center justify-center cursor-pointer top-1/2 lg:top-1/2 w-12 h-12"
                    id="button_prev"
                  >
                    <Arrow
                      color="#fff"
                      className="hidden rotate-180 dark:block"
                    />
                    <Arrow
                      color="#000"
                      className="block rotate-180 dark:hidden"
                    />
                  </div>
                </div>
              </Dialog>
            </Fragment>
            <h2 className="mb-10 text-5xl text-center text-black dark:text-white lg:mb-20 font-Inria">
              Closet
            </h2>
            <p className="text-sm text-black dark:text-white   lg:w-[708px] text-center mx-auto mb-8 px-3">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content.In publishing and
              graphic design, Lorem ipsum is a placeholder text commonly used to
              demonstrate the visual form of a document or a typeface without
              relying on meaningful content.
            </p>
            <div className="relative w-screen lg:pb-5">
              <Swiper {...mainswiperinit}>
                <SwiperSlide>
                  <div className="w-full overflow-hidden " onClick={handleOpen}>
                    <img
                      src={closet1}
                      alt=""
                      className="w-full cursor-pointer aspect-square"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full overflow-hidden " onClick={handleOpen}>
                    <img
                      src={closet2}
                      alt=""
                      className="w-full cursor-pointer aspect-square"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full overflow-hidden " onClick={handleOpen}>
                    <img
                      src={closet3}
                      alt=""
                      className="w-full cursor-pointer aspect-square"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full overflow-hidden " onClick={handleOpen}>
                    <img
                      src={closet4}
                      alt=""
                      className="w-full cursor-pointer aspect-square"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
              <div
                className="absolute flex left-0 lg:left-10 z-[1000] rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-12 h-12"
                id="main_next"
                // ref={nextRef3}
              >
                <Arrow />
              </div>
              <div
                className="absolute flex right-0 lg:right-10 z-[1000] rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-12 h-12"
                id="main_perv"
                // ref={prevRef3}
              >
                <Arrow className="rotate-180" />
              </div>
            </div>
          </div> */}
           <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More Closet design"}
          dialogTitle={"Closet"}
          img={[
            closet1,
            closet2,
            closet3,

            closet4,
            closet1,
            closet2,
            closet3,

            closet4,
            closet1,
            closet2,
            closet3,

            closet4,
            closet1,
            closet2,
            closet3,

            closet4,
          ]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        />
        </ReactPageScroller>
      </div>
      <div className="block lg:hidden">
        <SampleViewOne
          title={"Closet"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
          }
          img={[closet2, closet1, closet4, closet3]}
        />
          <TopRight
            title={"Bathroom 2"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content..."
            }
            img={[closet2, closet1, closet4, closet3]}
          />
        {/* <div
      id="page2-1"
      className="flex flex-col justify-start w-screen h-screen pt-20 overflow-auto bg-white dark:bg-black lg:pt-40 bg-opacity-90"
    >
      <Fragment>
        <Dialog
          open={open}
          handler={handleOpen}
          className="bg-white dark:bg-black"
          size="xxl"
        >
          <div className="w-screen h-screen py-32 ">
            <button
              onClick={handleOpen}
              className="absolute p-5 border-2 border-black rounded dark:bg-white top-10 left-10 lg:top-30 lg:left-32"
            >
              <CloseIcon color="#fff" className="hidden dark:block" />
              <CloseIcon color="#000" className="block dark:hidden" />
            </button>
            <div className="w-full mx-auto lg:w-3/4">
              <Swiper {...swiperInit}>
                <SwiperSlide>
                  <div className="w-full overflow-hidden ">
                    <img
                      src={closet1}
                      alt=""
                      className="w-full aspect-square"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full overflow-hidden ">
                    <img
                      src={closet2}
                      alt=""
                      className="w-full aspect-square"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full overflow-hidden ">
                    <img
                      src={closet3}
                      alt=""
                      className="w-full aspect-square"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full overflow-hidden ">
                    <img
                      src={closet4}
                      alt=""
                      className="w-full aspect-square"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div
              className="border dark:border-white border-black rounded-full absolute left-[0] lg:left-10 z-[1000]  flex items-center justify-center cursor-pointer  top-1/2 lg:top-1/2  w-12 h-12"
              id="button_next"
            >
              <Arrow color="#fff" className="hidden dark:block" />
              <Arrow color="#000" className="block dark:hidden" />
            </div>
            <div
              className=" border dark:border-white border-black  rounded-full absolute right-[0] lg:right-10 z-[1000] flex items-center justify-center cursor-pointer top-1/2 lg:top-1/2 w-12 h-12"
              id="button_prev"
            >
              <Arrow color="#fff" className="hidden rotate-180 dark:block" />
              <Arrow color="#000" className="block rotate-180 dark:hidden" />
            </div>
          </div>
        </Dialog>
      </Fragment>
      <h2 className="mb-10 text-5xl text-center text-black dark:text-white lg:mb-20 font-Inria">
        Closet
      </h2>
      <p className="text-sm text-black dark:text-white   lg:w-[708px] text-center mx-auto mb-8 px-3">
        In publishing and graphic design, Lorem ipsum is a placeholder text
        commonly used to demonstrate the visual form of a document or a typeface
        without relying on meaningful content.In publishing and graphic design,
        Lorem ipsum is a placeholder text commonly used to demonstrate the
        visual form of a document or a typeface without relying on meaningful
        content.
      </p>
      <div className="relative w-screen lg:pb-5">
        <Swiper {...mainswiperinit}>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img
                src={closet1}
                alt=""
                className="w-full cursor-pointer aspect-square"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img
                src={closet2}
                alt=""
                className="w-full cursor-pointer aspect-square"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img
                src={closet3}
                alt=""
                className="w-full cursor-pointer aspect-square"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full overflow-hidden " onClick={handleOpen}>
              <img
                src={closet4}
                alt=""
                className="w-full cursor-pointer aspect-square"
              />
            </div>
          </SwiperSlide>
          
        </Swiper>
        <div
          className="absolute flex left-0 lg:left-10 z-[1000] rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-12 h-12"
          id="main_next"
          // ref={nextRef3}
        >
          <Arrow />
        </div>
        <div
          className="absolute flex right-0 lg:right-10 z-[1000] rounded-full lg:flex items-center justify-center cursor-pointer top-1/3 lg:top-1/2 bg-[#171717] w-12 h-12"
          id="main_perv"
          // ref={prevRef3}
        >
          <Arrow className="rotate-180" />
        </div>
      </div>
      
    </div>
     */}

        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More Closet design"}
          dialogTitle={"Closet"}
          img={[
            closet1,
            closet2,
            closet3,

            closet4,
            closet1,
            closet2,
            closet3,

            closet4,
            closet1,
            closet2,
            closet3,

            closet4,
            closet1,
            closet2,
            closet3,

            closet4,
          ]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        />
      </div>

      <FooterMain classNameprops="relative lg:absolute " />
    </>
  );
};

export default Closet;
