import { FunctionComponent, ReactNode, useState } from "react";
import TopCenter from "../../components/pageViews/topCenter";

import TopRight from "../../components/pageViews/topright";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import BottomLeft from "../../components/pageViews/bottomLeft";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import FooterMain from "../../components/footer";

import bedroom1 from "../../assets/image/bedroom/C5487872_16.jpg";
import bedroom2 from "../../assets/image/bedroom/C5744904_19.jpg";
import bedroom3 from "../../assets/image/bedroom/e1b8a_34.jpg";

interface BedroomPageProps {}

const BedroomPage: FunctionComponent<BedroomPageProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <div className="hidden lg:block">
        <ReactPageScroller {...pageScrollerProps}>
          <TopCenter
            title={"BEDROOM1"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
            }
            img={[bedroom1]}
          />
          <TopRight
            title={"BEDROOM2"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
            }
            img={[bedroom2]}
          />
          {/* <BottomLeft
            title={"BEDROOM3"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
            }
            img={[bedroom3]}
          /> */}

          <MoreDetailsSlider
            title={" More bedroom design"}
            onSlideChange={handleSwiperSlideChange}
            dialogTitle={"bedroom"}
            img={[bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3]}
            content={[
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
            ]}
          >
            <FooterMain />
          </MoreDetailsSlider>
        </ReactPageScroller>
      </div>
      <div className="block lg:hidden">
        <TopCenter
          title={"BEDROOM1"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
          }
          img={[bedroom1]}
        />
        <TopRight
          title={"BEDROOM2"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
          }
          img={[bedroom2]}
        />
        {/* <BottomLeft
          title={"BEDROOM3"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
          }
          img={[bedroom3]}
        /> */}
        <MoreDetailsSlider
          title={" More bedroom design"}
          onSlideChange={handleSwiperSlideChange}
          dialogTitle={"bedroom"}
          img={[bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        >
          <FooterMain />
        </MoreDetailsSlider>
      </div>
    </>
  );
};

export default BedroomPage;
