import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowRight from "../../assets/icon/arrowRight";

import ArrowLeftSlider from "../../assets/icon/arrowLeftSlider";
import ArrowRightSlider from "../../assets/icon/arrowRightSlider";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import MoreDetailsPage from "../moreDetails/moreDetails";
import { Dialog } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
interface SampleViewOneProps {
  title: string;
  content: string;
  img: any[];
}

const SampleViewOne: FunctionComponent<SampleViewOneProps> = ({
  title,
  img,
  content,
}) => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [moreDetailsData, setMoreDetailsData] = useState<{
    title: string;
    content: string;
    image: any;
  }>({
    title: "",
    content: "",
    image: "",
  });
  const navigate = useNavigate();

  const location= useLocation()
  useEffect(()=>{
    if(location.hash===''){
      setShowMoreDetails(false);
    }
   },[location])
  const handleOpen = () => {
    setShowMoreDetails(!showMoreDetails);
    alert(showMoreDetails)
     if(showMoreDetails===true){
      navigate(window.location.pathname)
    }
  };

  const OnClickSlide = (x: number) => {
    if (x == 1) {
      // alert("salam");
    } else {
      // alert("khodafez");
    }
  };
  const swiperInit = {
    modules: [Navigation,Autoplay],
    dir: "rtl",
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    speed: 1200,
    // allowTouchMove: false,
    loop: true,
    touchStartForcePreventDefault:true,
   autoplay:{
      delay: 2500,
      disableOnInteraction: false,
    },

  };
  const backswiperInit = {
    dir: "ltr",
    modules: [Navigation,Autoplay],
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    speed: 1700,
    // allowTouchMove: false,
    loop: true,
    touchStartForcePreventDefault:true,
   autoplay:{
      delay: 2500,
      disableOnInteraction: false,
    },

  };
  return (
    <>
      {showMoreDetails && (
        <Dialog open={showMoreDetails} handler={handleOpen} size="xxl">
          <MoreDetailsPage
            title={moreDetailsData.title}
            content={moreDetailsData.content}
            image={moreDetailsData.image}
            handleClick={handleOpen}
          />
        </Dialog>
      )}
      <div
        id="page1-2"
        className="relative w-screen h-screen bg-black bg-opacity-50 bg-center bg-no-repeat bg-cover page "
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 ">
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-white dark:bg-black dark:bg-opacity-60 bg-opacity-30"></div>
          <Fade bottom>
            <Swiper {...backswiperInit} className="w-screen h-screen">
              {img &&
                img.length > 0 &&
                img?.map((item) => {
                  return (
                    <SwiperSlide className="text-center">
                      <img src={item} alt="" className="absolute h-full lg:w-full max-w-none"
/>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Fade>
        </div>
        <div className="relative z-30 w-screen h-screen">
          <Fade left big duration={2000}>
            <div className="lg:absolute  z-10 after:-z-10 py-32 aspect-[16/1] w-3/5 mx-auto lg:top-1/4 lg:pl-[180px] lg:pr-[230px]   lg:w-3/5 bg-mainColor lg:after:bg-mainColor  lg:after:absolute lg:after:w-3 lg:after:top-0 lg:after:bottom-0 lg:after:-right-5 "></div>
          </Fade>
          <Fade left big duration={2000} delay={100}>
          <div className="absolute z-20 lg:w-1/3 lg:m-10 lg:left-32 lg:top-1/4 ml-10  w-[80%] m-10 bottom-16 lg:ml-auto lg:bottom-auto text-white lg:text-black">
            {/* <Fade left big duration={1000}> */}
            <h2 className="mb-4 mr-4 text-3xl font-bold text-black lg:text-4xl font-Inria dark:text-white">
              {title}
            </h2>
            <p className="mb-5 text-xs text-black lg:text-base dark:text-white">{content}</p>
            <button
            className="text-black dark:text-white"
              onClick={() => {
                setMoreDetailsData({
                  title: title,
                  content: content,
                  image: img[0],
                });
                setShowMoreDetails(true);
                navigate(window.location.pathname+`#content${ title}`)
              }}
            >
              More detail
              <ArrowRight className="inline ml-2 stroke-black dark:stroke-white" />
            </button>
            {/* </Fade> */}
          </div>
          </Fade>
          <Fade right big duration={2000}>
            <div className="absolute lg:left-1/2 z-40 lg:top-[40%] mt-[35%] top-0  ml-10 lg:mt-auto  imageShadow w-[80%] mx-auto lg:w-[500px] aspect-[4/3]">
              <Swiper {...swiperInit}>
                {img &&
                  img.length > 0 &&
                  img?.map((item) => {
                    return (
                      <SwiperSlide>
                        <img src={item} alt=""  className="cursor-pointer"  onClick={() => {
                setMoreDetailsData({
                  title: title,
                  content: content,
                  image: img[0],
                });
                setShowMoreDetails(true);
                navigate(window.location.pathname+`#content${ title}`)
              }}/>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </Fade>
        </div>
        <div
          id="prevRef"
          className="absolute z-50 right-5 lg:right-10 top-1/3 lg:top-1/2"
        >
          <ArrowLeftSlider className="w-8 transition-all cursor-pointer stroke-black dark:stroke-white hover:stroke-mainColor lg:w-auto" />
        </div>
        <div
          id="nextRef"
          className="absolute z-[9999] left-5 lg:left-10 top-1/3 lg:top-1/2"
        >
          <ArrowLeftSlider className="w-8 transition-all rotate-180 cursor-pointer stroke-black dark:stroke-white hover:stroke-mainColor lg:w-auto " />
          {/* <ArrowRightSlider className="stroke-white hover:stroke-mainColor"/> */}
        </div>
      </div>
    </>
  );
};

export default SampleViewOne;
